import styled from 'styled-components';
export const GridContainer = styled.div.withConfig({
  displayName: "RelatedGridstyle__GridContainer",
  componentId: "sc-jeqblx-0"
})(["", " max-width:", ";"], {
  "marginLeft": "auto",
  "marginRight": "auto",
  "width": "100%"
}, ({
  theme
}) => `${theme.layout.width}`);
export const GridContent = styled.div.withConfig({
  displayName: "RelatedGridstyle__GridContent",
  componentId: "sc-jeqblx-1"
})(["", " overflow-x:", ";grid-template-columns:", ";grid-auto-columns:", ";@media (max-width:", "){", "}@media (max-width:", "){max-width:100vw;overflow-x:", ";grid-template-columns:", ";grid-auto-columns:", ";}"], {
  "marginTop": "1.5rem",
  "display": "grid",
  "gridAutoFlow": "column",
  "gap": "2rem",
  "paddingBottom": "1.25rem"
}, ({
  $isScrollDesktop
}) => $isScrollDesktop ? 'scroll' : 'unset', ({
  $desktopColumn,
  $isScrollDesktop
}) => $isScrollDesktop ? 'unset' : `repeat(${$desktopColumn}, minmax(0, 1fr))`, ({
  $desktopColumn,
  $isScrollDesktop
}) => $isScrollDesktop ? `calc(100% / ${$desktopColumn ?? 1} - ${55 / $desktopColumn || 1}px)` : 'unset', ({
  theme
}) => theme.breakpoint.xl, {
  "gap": "1rem",
  "paddingLeft": "1rem",
  "paddingRight": "1rem"
}, ({
  theme
}) => theme.breakpoint.md, ({
  $isScrollMobile
}) => $isScrollMobile ? 'scroll' : 'unset', ({
  $mobileColumn,
  $isScrollMobile
}) => $isScrollMobile ? 'unset' : `repeat(${$mobileColumn}, minmax(0, 1fr))`, ({
  $mobileColumn,
  $isScrollMobile
}) => $isScrollMobile ? `calc(100% / ${$mobileColumn ?? 1} - ${55 / $mobileColumn || 1}px)` : 'unset');