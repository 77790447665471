import { Skeleton } from 'antd';
import styled from 'styled-components';
export const Container = styled.div.withConfig({
  displayName: "Cardstyle__Container",
  componentId: "sc-6vsowx-0"
})(["", " @media (max-width:", "){", "}"], {
  "padding": "9px"
}, ({
  theme
}) => theme.breakpoint.md, {
  "padding": "0px"
});
export const SkeletonImage = styled(Skeleton.Image).withConfig({
  displayName: "Cardstyle__SkeletonImage",
  componentId: "sc-6vsowx-1"
})(["", " svg{", "}"], {
  "aspectRatio": "16 / 9",
  "height": "auto !important",
  "width": "100% !important",
  "borderRadius": "0.5rem !important"
}, {
  "display": "none"
});
export const SkeletonDate = styled(Skeleton.Input).withConfig({
  displayName: "Cardstyle__SkeletonDate",
  componentId: "sc-6vsowx-2"
})(["", " @media (max-width:", "){", "}"], {
  "marginTop": "0.125rem",
  "height": "1.25rem !important",
  "width": "100px !important",
  "minWidth": "100px !important"
}, ({
  theme
}) => theme.breakpoint.md, {
  "marginTop": "-0.25rem",
  "height": "1rem !important"
});
export const SkeletonTitle = styled(Skeleton.Input).withConfig({
  displayName: "Cardstyle__SkeletonTitle",
  componentId: "sc-6vsowx-3"
})(["", " @media (max-width:", "){", "}"], {
  "marginTop": "-0.5rem",
  "height": "1.75rem !important",
  "width": "91.666667% !important"
}, ({
  theme
}) => theme.breakpoint.md, {
  "marginTop": "-1rem",
  "height": "1.5rem !important"
});
export const Description = styled.div.withConfig({
  displayName: "Cardstyle__Description",
  componentId: "sc-6vsowx-4"
})(["", " @media (max-width:", "){", "}"], {
  "marginTop": "-0.5rem",
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.25rem"
}, ({
  theme
}) => theme.breakpoint.md, {
  "marginTop": "-1.25rem"
});
export const SkeletonDescription = styled(Skeleton.Input).withConfig({
  displayName: "Cardstyle__SkeletonDescription",
  componentId: "sc-6vsowx-5"
})(["", " @media (max-width:", "){", "}"], {
  "height": "1.25rem !important",
  "width": "100% !important"
}, ({
  theme
}) => theme.breakpoint.md, {
  "height": "1rem !important"
});
export const TipsAction = styled.div.withConfig({
  displayName: "Cardstyle__TipsAction",
  componentId: "sc-6vsowx-6"
})(["", " img{", "}"], {
  "marginTop": "0.5rem",
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "space-between"
}, {
  "marginBottom": "0.25rem"
});