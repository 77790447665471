import { FC } from 'react';
import Link from 'next/link';
import { isEmpty } from 'radash';
import { useUtag } from '@shared/utils/hooks/useUtag.utils';
import { Wrapper } from './SeeAll.style';
type Props = {
  url: string;
  pathForTracking: string;
};
const SeeAll: FC<Props> = ({
  url,
  pathForTracking
}: Props) => {
  const utag = useUtag();
  const handleOnClickTracker = () => {
    if (isEmpty(pathForTracking)) return false;
    utag.link({
      tealium_event: `${pathForTracking}_see_all_click`
    });
  };
  return <Wrapper $fill={"#EE843C"} $hoverColor={"#CE0E2D"} onClick={() => handleOnClickTracker()}>
            <Link href={url}>
                <div>
                    ดูทั้งหมด
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M14.4319 5.18262C14.6219 5.18262 14.8119 5.25262 14.9619 5.40262L21.0319 11.4726C21.3219 11.7626 21.3219 12.2426 21.0319 12.5326L14.9619 18.6026C14.6719 18.8926 14.1919 18.8926 13.9019 18.6026C13.6119 18.3126 13.6119 17.8326 13.9019 17.5426L19.4419 12.0026L13.9019 6.46262C13.6119 6.17262 13.6119 5.69262 13.9019 5.40262C14.0419 5.25262 14.2419 5.18262 14.4319 5.18262Z" />
                        <path d="M3.50195 11.2527L20.332 11.2527C20.742 11.2527 21.082 11.5927 21.082 12.0027C21.082 12.4127 20.742 12.7527 20.332 12.7527L3.50195 12.7527C3.09195 12.7527 2.75195 12.4127 2.75195 12.0027C2.75195 11.5927 3.09195 11.2527 3.50195 11.2527Z" />
                    </svg>
                </div>
            </Link>
        </Wrapper>;
};
export default SeeAll;