import { Skeleton } from 'antd';
import styled from 'styled-components';
export const CustomSkeletonTag = styled(Skeleton.Button).withConfig({
  displayName: "Tagstyle__CustomSkeletonTag",
  componentId: "sc-ts44jl-0"
})(["", " @media (max-width:", "){", "}"], {
  "height": "1.75rem !important"
}, ({
  theme
}) => theme.breakpoint.md, {
  "height": "1.5rem !important"
});