import { FC } from 'react';
import { useWindowSize } from '@shared/utils/hooks';
import breakpoint from '../../../../shared/assets/breakpoint.json';
import { CustomSkeletonTag } from './Tag.style';
const SkeletonTag: FC = () => {
  const {
    width
  } = useWindowSize();
  return <CustomSkeletonTag active size={width > breakpoint.md ? 'default' : 'small'} />;
};
export default SkeletonTag;