import { FC, Fragment, ReactNode, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { isEmpty } from 'radash';
import { match } from 'ts-pattern';
import { IActivityCard } from '@modules/activity/domain/models/activity.model';
import { IPromotionCard } from '@modules/promotion/domain/models/promotion.model';
import SkeletonTipsAndTricksCard from '@components/skeleton/tipsAndTricks/card/Card';
import { IRelatedProduct } from '@shared/models/relatedProduct.model';
import { IRelatedRecipe } from '@shared/models/relatedRecipe.model';
import { ITipCard } from '@shared/models/tipsAndTricks.model';
import { ModuleType } from '@shared/types/module.type';
import { useUtag } from '@shared/utils/hooks/useUtag.utils';
import { GridContainer, GridContent } from './RelatedGrid.style';
const ProductCard = dynamic(() => import('@components/product/card/ProductCard'));
const RecipeCard = dynamic(() => import('@components/recipe/card/RecipeCard'));
const TipsAndTricksCard = dynamic(() => import('@components/tipsAndTricks/card/Card'));
const ActivityCardVertical = dynamic(() => import('@components/activity/card/vertical/ActivityCardVertical'));
const PromotionCardVertical = dynamic(() => import('@components/promotion/card/vertical/PromotionCardVertical'));
interface Props {
  cardType: ModuleType;
  title?: ReactNode;
  dataList: IRelatedProduct[] | IRelatedRecipe[] | ITipCard[] | IActivityCard[] | IPromotionCard[];
  desktopColumn: number;
  mobileColumn: number;
  eventSection: string;
}
const RelatedGrid: FC<Props> = ({
  cardType,
  title,
  dataList,
  desktopColumn,
  mobileColumn,
  eventSection
}: Props) => {
  const utag = useUtag();
  useEffect(() => {
    const gridContentEl = Array.from(document.getElementsByClassName('grid-content'));
    gridContentEl.forEach(element => element.scrollLeft = 0);
  }, [dataList]);
  const onClickCard = (cardType: ModuleType, id: number, position: number) => {
    const keyId = match(cardType).with(ModuleType.Recipe, () => 'recipe_id').with(ModuleType.Product, () => 'product_id').with(ModuleType.TipsTricks, () => 'tips_tricks_id').with(ModuleType.Activity, () => 'news_id').with(ModuleType.Promotion, () => 'promotion_id').otherwise(() => '');
    utag.link({
      tealium_event: `${eventSection}_click`,
      [keyId]: id,
      position
    });
  };
  const renderContent = () => {
    return match(cardType).with(ModuleType.Product, () => {
      return dataList.map((data, index) => <div onClick={() => onClickCard(cardType, data.id, index + 1)} key={data.id}>
                        <ProductCard imageUrl={data.imageUrl} title={data.name} weight={data.description} tag={data.tag} slug={data.slugUrl} />
                    </div>);
    }).with(ModuleType.Recipe, () => {
      return dataList.map((data, index) => <div onClick={() => onClickCard(cardType, data.id, index + 1)} key={data.id}>
                        <RecipeCard imageUrl={data.imageUrl} viewer={data.viewer} name={data.name} slug={data.slug} tagList={data.tag} />
                    </div>);
    }).with(ModuleType.TipsTricks, () => {
      return dataList.map((data, index) => <div onClick={() => onClickCard(cardType, data.id, index + 1)} key={data.id}>
                        <TipsAndTricksCard imageUrl={data.imageUrl} date={data.publishDate} title={data.title} description={data.description} tag={data.categories} slug={data.slugUrl} viewer={data.viewer} />
                    </div>);
    }).with(ModuleType.Activity, () => {
      return dataList.map((data, index) => <div onClick={() => onClickCard(cardType, data.id, index + 1)} key={data.id}>
                        <ActivityCardVertical imageUrl={data.imageUrl} title={data.title} description={data.description} startDate={data.startDate} endDate={data.endDate} slug={data.slugUrl} viewer={data.viewer} isExpired={data.isExpired} />
                    </div>);
    }).with(ModuleType.Promotion, () => {
      return dataList.map((data, index) => <div onClick={() => onClickCard(cardType, data.id, index + 1)} key={data.id}>
                        <PromotionCardVertical imageUrl={data.imageUrl} title={data.title} description={data.description} startDate={data.startDate} endDate={data.endDate} slug={data.slugUrl} viewer={data.viewer} isExpired={data.isExpired} />
                    </div>);
    }).otherwise(() => <Fragment />);
  };
  return <GridContainer>
            {title}
            <GridContent className="grid-content" $desktopColumn={desktopColumn} $mobileColumn={mobileColumn} $isScrollDesktop={dataList.length > desktopColumn} $isScrollMobile={dataList.length > mobileColumn}>
                {!isEmpty(dataList) ? renderContent() : <>
                        <SkeletonTipsAndTricksCard />
                        <SkeletonTipsAndTricksCard />
                        <SkeletonTipsAndTricksCard />
                    </>}
            </GridContent>
        </GridContainer>;
};
export default RelatedGrid;